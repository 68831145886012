import { CheckCircleIcon } from "@heroicons/react/outline";
import { graphql, Link } from "gatsby";
import React from "react"
import Layout from '../components/layout';
import SEO from "../components/seo";

function convertToSlug(Text)
  {
      return Text
          .toString()
          .toLowerCase()
          .replace(/ /g,'-')
          .replace(/[^\w-]+/g,'')
          ;
  }

const InspirationSinglePost = ({data}) => {
    const postData = data.allposts ? data.allposts.data : [];
    const otherPostsData = data.otherCompanyTemplates ? data.otherCompanyTemplates.edges : [];
    const otherCompanyTemplates = data.similarTemplates ? data.similarTemplates.edges : [];
    console.log(data);
    const postTitle = `${postData.HeadingKeyword} ${postData.EmailtypeText[0].toString().toLowerCase()} design from ${postData.Name__from_Companies_}`
    const postDescription = `See how ${postData.Name__from_Companies_} designed their ${postData.EmailtypeText[0].toString().toLowerCase()} that they send to their users. Emailkick inspiration is a collection of best email designs made by product companies across the globe. `
    return (
        <Layout logoText={'Inspirations'} >
                <SEO 
                    description={`${postDescription}`}
                    title={`${postTitle}`} 
                    author="Athul Suresh"
                />

                <div className='py-12 md:py-14 bg-gray-50'>
                    <div className="border-b border-gray-200 mb-6 bg-white shadow-sm">
                        <div className="max-w-7xl mx-auto  px-4 lg:px-0">
                            

                            <div className="flex pt-4 pb-2 text-sm text-gray-400">
                                <a href="/email-inspirations/" className="text-gray-500 mr-2 inline-block hover:underline hover:text-gray-700 ">Inspiration</a> /  <a href={`/email-inspirations/${convertToSlug(postData.Name__from_Companies_)}`} className="text-gray-500  mx-2 inline-block hover:underline hover:text-gray-700">{postData.Name__from_Companies_}</a> / <span className=" text-gray-900 ml-2 inline ">{postData.Subject}</span>
                            </div>

                            <div className="pt-2 pb-4">
                                <h1 className="text-2xl font-medium text-red-750">
                                {/* {postData.HeadingKeyword} {postData.EmailtypeText[0].toString().toLowerCase()} from {postData.Name__from_Companies_} */}
                                {postTitle}
                                </h1>
                            </div>
                        </div>
                    </div>

                    <div className="max-w-7xl mx-auto flex md:flex-row flex-col px-4 lg:px-0">
                        <div className="w-full md:w-7/12">
                            {
                                postData.Attachments.map(attachedImg => (
                                    <img key={attachedImg.url} alt={`${postData.Subject} email by ${postData.Name__from_Companies_}`} src={attachedImg.url} className="w-full p-1 border border-gray-200 bg-white shadow-sm"></img>
                                ))
                            }
                           
                        </div>
                        <div className="w-full md:w-5/12 md:sticky mt-4 md:mt-0 md:ml-4">
                            <div className=" bg-white rounded-md border border-gray-200 shadow-sm">
                                <div className="py-4 px-4 border-b border-gray-100">
                                    <span className="block text-xs text-gray-400">Subject</span>
                                    <h2 className="text-xl font-medium text-gray-900">{postData.Subject}</h2>
                                    <div className="mt-1 text-sm">
                                        <span className="text-gray-400">By</span>
                                        <span className="ml-1 "><a href='#' className="text-gray-700 hover:underline">{postData.Name__from_Companies_}</a></span>
                                    </div>
                                </div>
                                    


                                    <div className="py-2 border-b border-gray-100">
                                        <div className="flex items-center px-4 py-2">
                                            <div className="w-2/12">
                                                <span className="text-xs text-gray-400">Tags</span>
                                            </div>
                                            <div className="flex  w-10/12">
                                                { postData.SimpleText__from_EmailType_.map((type, index) => (
                                                    <Link to={`/email-inspirations/category/${postData.SimpleText__from_EmailType_}/`} className="px-2 py-1 rounded-3xl bg-yellow-100 hover:bg-yellow-200 text-yellow-800">{postData.Name__from_EmailType_[index]}</Link>
                                                )) }
                                            </div>
                                        </div>


                                        <div className="flex items-center px-4 py-2">
                                            <div className="w-2/12">
                                                <span className="text-xs text-gray-400">Added by</span>
                                            </div>
                                            <div className="flex  w-10/12">
                                                <span className="text-gray-800">Athul Suresh</span>
                                            </div>
                                        </div>

                                        <div className="flex items-center px-4 py-2">
                                            <div className="w-2/12">
                                                <span className="text-xs text-gray-400">Posted</span>
                                            </div>
                                            <div className="flex  w-10/12">
                                                <span className="text-gray-800">{postData.publishedDate}</span>
                                            </div>
                                        </div>

                                        <div className="flex items-center px-4 py-2">
                                            <div className="w-2/12">
                                                <span className="text-xs text-gray-400">Website</span>
                                            </div>
                                            <div className="flex  w-10/12">
                                                {postData.website__from_Companies_.map(lin => 
                                                 <a href={lin} target="_blank" className="text-blue-800 hover:underline">{lin}</a>
                                                )}
                                               
                                            </div>
                                        </div>

                                       
                                    </div>




                                    {/******** Good Things ***********/}
                                    {postData.Good_things ?

                                    <div className="py-4 border-b border-gray-100">
                                        <div className="px-4">
                                            <h4 className="text-gray-700 text-base">What is great about this email?</h4>
                                            <ul className="mt-1">
                                                {postData.Good_things.map(goodTags => (
                                                    <li className="py-1"><div className="text-sm flex items-center"><span className="font-sm text-green-600 mr-2 w-4"><CheckCircleIcon /></span>{goodTags}</div></li>
                                                ))}
                                               
                                            </ul>

                                        </div>
                                    </div> : null }


                                    {/*** Emailkick Plug */}


                                    <div className="py-4 border-b border-gray-200">
                                        <div className="px-4">
                                            
                                            <h4 className="text-base">What to quickly create similar {postData.EmailtypeText[0].toString().toLowerCase()} for your product?</h4>
                                            <p className="text-sm mt-2 md:mt-1">Sign up to <a rel="nofollow" href="https://app.emailkick.com/register/" className="text-blue-800 hover:text-blue-900 hover:underline">Emailkick</a> to design emails with <a href='/email-designer' className="text-blue-800 hover:text-blue-900 hover:underline">ease</a>.</p>
                                        </div>
                                    </div>



                                    {/*** Other Email Designs */}
                                    { otherPostsData.length > 0 ? 
                                    <div className="py-4 border-b border-gray-200">
                                        <div className="px-4">
                                            
                                            <h4 className="text-base">Other emails designs from {postData.Name__from_Companies_}</h4>
                                            <div className="mt-2">
                                                {otherPostsData.map(({node}) => {
                                                        return(<a href={`/email-inspirations/${convertToSlug(node.data.Name__from_Companies_)}/${convertToSlug(node.data.Subject) }`} className="flex items-center border border-gray-200 hover:border-gray-300 hover:shadow-sm duration-75 p-1 rounded-md my-4">
                                                            <div className="w-2/12"><img style={{ maxHeight: '80px'}} src={node.data.Attachments[0].thumbnails.large.url} className="w-full p-1 object-cover border border-gray-1"></img></div>
                                                            <div className="w-10/12 px-4">
                                                                <div className="text-sm text-gray-700 px-2 py-1 bg-gray-100 rounded-lg inline-block mb-1">{node.data.Name__from_EmailType_[0]}</div>
                                                                <div className="text-sm">{node.data.Subject}</div>
                                                            </div>
                                                        </a>)
                                                })}

                                                

                                                
                                            </div>
                                            {/* <p className="text-sm mt-1">Sign up to <a rel="no-follow" href="https://app.emailkick.com/register/" className="text-blue-800 hover:text-blue-900 hover:underline">Emailkick</a> to design emails with ease. <a href='/email-designer' className="text-blue-800 hover:text-blue-900 hover:underline">Learn more</a></p> */}
                                        </div>
                                    </div> : null }





                            </div>
                        </div>


                    </div>
                </div>


                <div className="mt-8">
                    <div className="max-w-7xl mx-auto">
                        <h3 className="text-base px-4 md:px-0">Similar {postData.EmailtypeText[0].toString().toLowerCase()}s made by others</h3>

                        <div className="grid grid-cols-2 md:grid-cols-4 md:gap-8 gap-2 px-4 md:px-0 mt-8">
                                {otherCompanyTemplates.map(({node}) => (
                                    <a href={`/email-inspirations/${convertToSlug(node.data.Name__from_Companies_)}/${convertToSlug(node.data.Subject) }`} className="flex flex-col items-center border border-gray-200 hover:border-gray-300 hover:shadow duration-75 rounded-md ">
                                        <div className="w-full">
                                            <img style={{ maxHeight: '200px'}} src={node.data.Attachments[0].thumbnails.large.url} className="w-full object-cover object-top border-b border-gray-200"></img></div>
                                        <div className="w-full px-4 py-4">
                                            {/* <div className="text-sm text-gray-700 px-2 py-1 bg-gray-100 rounded-lg inline-block mb-1">{node.data.Name__from_EmailType_[0]}</div> */}
                                            <div className="text-sm">{node.data.Subject}</div>
                                            <div className="text-sm text-gray-500 mt-2">{node.data.Name__from_Companies_}</div>
                                        </div>
                                    </a>
                                ))}
                        </div>
                    </div>
                </div>
        </Layout>
       
    )
}

export const pageQuery = graphql`
  query($id: String!, $company: String!, $emailtype: String!) {
    allposts: airtable(id: {eq: $id}) {
        id
        data {
            Attachments {
                filename
                id
                url
            }
            Company
            EmailType
            Emails
            Name__from_Companies_
            Status
            Subject
            website
            website__from_Companies_
            HeadingKeyword
            Good_things
            publishedDate(fromNow: true)
            SimpleText__from_EmailType_
            Name__from_EmailType_
            EmailtypeText
        }
      }

      otherCompanyTemplates: allAirtable(filter: {data: {Name__from_Companies_: { in: [$company] } },  id: { ne: $id }   }) {
        edges {
          node {
            data {
              Attachments {
                thumbnails {
                  large {
                    url
                  }
                }
              }
              Name__from_EmailType_
              Subject
              HeadingKeyword
              SimpleText__from_EmailType_
              Name__from_Companies_
              
            }
          }
        }
      }


      similarTemplates: allAirtable(filter: { data: {SimpleText__from_EmailType_: {in: [$emailtype]}}, id: { ne: $id } }) {
        edges {
          node {
            id
            data {
              Name__from_Companies_
              SimpleText__from_EmailType_
              Name__from_EmailType_
              Company
              Subject
              Attachments {
                thumbnails {
                  large {
                    url
                  }
                }
              }
            }
          }
        }
      }
  }
`


export default InspirationSinglePost;